<template>
  <div>
    <Pane>
      <template v-slot:extra>
        <Draft @select="onSelectDraft" />
      </template>
    </Pane>

    <a-modal
      :visible="draftVisible"
      title="保存草稿"
      @ok="saveDraft"
      @cancel="cancelDraft"
    >
      <div style="padding: 8px 0">
        <a-input placeholder="请输入草稿标题" v-model="draftTitle" />
      </div>
    </a-modal>

    <a-card class="container">
      <Header title="EPC项目预计总成本表" />

      <div class="content">
        <div class="title">
          <span class="value">项目信息</span>
        </div>

        <a-descriptions bordered size="small" :column="2">
          <a-descriptions-item>
            <div slot="label" class="center">合同编号</div>
            <div>
              <ContractSelector @change="onSelectContract">
                <span style="cursor: pointer; color: #1890ff">
                  <span v-if="selectedContract.name">{{
                    selectedContract.code
                  }}</span>
                  <span v-else>请选择</span>
                </span>
              </ContractSelector>
            </div>
          </a-descriptions-item>
          <a-descriptions-item>
            <div slot="label" class="center">合同名称</div>
            <div>
              {{ selectedContract.name }}
            </div>
          </a-descriptions-item>
          <a-descriptions-item>
            <div slot="label" class="center">总承包总价（元）</div>
            <div>
              <a-input-number
                style="width: 120px"
                :formatter="$inputNumberFormat"
                v-model="totalAmt"
                placeholder="请输入"
                size="small"
              />
            </div>
          </a-descriptions-item>
        </a-descriptions>

        <div class="title">
          <span class="value">金额明细</span>
          <span class="extra">
            <a-button type="primary" @click="amtList.push({})">新增</a-button>
          </span>
        </div>

        <AmptList :totalList="amtTotalList" @delete="onDeleteAmtList" />

        <div class="title">
          <span class="value">项目总支出</span>
          <span class="extra">
            <a-button type="primary" @click="addExp">新增</a-button>
          </span>
        </div>

        <ExpList :list="expList" :all="expAll" @delete="onDeleteExpList" />

        <div class="title">
          <span class="value">项目利润</span>
        </div>

        <ProfitList :list="profitList" />

        <div class="title">
          <span class="value">预计总成本表</span>
          <span class="extra">
            <a-button
              type="primary"
              @click="
                estCostList.push({
                  fileList: [],
                })
              "
              >新增</a-button
            >
          </span>
        </div>

        <EstCostList :list="estCostList" @delete="onDeleteEstCostList" />

        <div class="tips" style="margin-top: 24px">
          提示："金额明细"和“项目总支出”里要分别包含"勘察设计费"和"勘察设计费分包"，否则项目利润的计算会异常。
        </div>

        <div class="footer">
          <a-space>
            <a-button @click="addDraft">存草稿</a-button>
            <a-button type="primary" @click="submit" :loading="saveLoading"
              >保存</a-button
            >
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </div>
    </a-card>
  </div>
</template>
  
<script>
import Header from "./components/header";
import ContractSelector from "@/components/contract-selector";
import AmptList from "./components/amtList.vue";
import ExpList from "./components/expList.vue";
import ProfitList from "./components/profitList.vue";
import EstCostList from "./components/estCostList.vue";

import accurate from "accurate";

import { update, fetchDetail } from "./api";

import draft from "@/mixins/draft";

import moment from "moment";
export default {
  mixins: [draft],

  components: {
    ContractSelector,
    Header,
    AmptList,
    ExpList,
    ProfitList,
    EstCostList,
  },
  data() {
    return {
      selectedContract: {},
      totalAmt: null,

      amtList: [], // 金额明细列表

      expList: [],

      estCostList: [],

      saveLoading: false,
    };
  },

  computed: {
    amtTotalList() {
      const arr = [...this.amtList];

      let amt = 0;
      let exTaxAmt = 0;
      arr.forEach((item) => {
        if (typeof item.amt === "number") {
          amt = accurate.add(amt, item.amt);
          exTaxAmt = accurate.add(exTaxAmt, this.getMoney(item));
        }
      });
      arr.push({
        amt,
        exTaxAmt,
      });
      return arr;
    },

    expAll() {
      if (this.expList.length > 0) {
        let amt = 0;
        let exTaxAmt = 0;
        this.expList.forEach((item) => {
          if (item.sort.indexOf(".") > 0 && typeof item.amt === "number") {
            amt = accurate.add(amt, item.amt);
            exTaxAmt = accurate.add(exTaxAmt, this.getMoney(item));
          }
        });
        return {
          sort: "合计",
          amt,
          exTaxAmt,
        };
      } else {
        return {
          sort: "合计",
          amt: 0,
          exTaxAmt: 0,
        };
      }
    },

    profitList() {
      const amtAll = this.amtTotalList[this.amtTotalList.length - 1];

      // 总金额减总成本
      const amt = accurate.subtract(amtAll.amt, this.expAll.amt);
      // 扣税总金额减扣税总成本
      const exTaxAmt = accurate.subtract(amtAll.exTaxAmt, this.expAll.exTaxAmt);

      let noDesignAmt = amtAll.amt; // 扣除了勘察设计费的总金额
      let noDesignExTaxAmt = amtAll.exTaxAmt; // 扣除了勘察设计费的不含税总金额
      const design1 = this.amtTotalList.find(
        (item) => item.name === "勘察设计费"
      );
      if (design1) {
        const { amt } = design1;
        noDesignAmt = accurate.subtract(noDesignAmt, amt ? amt : 0);
        noDesignExTaxAmt = accurate.subtract(
          noDesignExTaxAmt,
          this.getMoney(design1)
        );
      }
      console.log("design1", design1);
      console.log("noDesignAmt", noDesignAmt);
      console.log("noDesignExTaxAmt", noDesignExTaxAmt);

      let noDesignCostAmt = this.expAll.amt; // 扣除了勘察设计成本的总成本
      let noDesignCostExTaxAmt = this.expAll.exTaxAmt; // 扣除了勘察设计费的不含税总成本
      const design2 = this.expList.find(
        (item) => item.name === "勘察设计费分包"
      );
      if (design2) {
        noDesignCostAmt = accurate.subtract(
          noDesignCostAmt,
          this.getCategoryMoney(design2)
        );
        noDesignCostExTaxAmt = accurate.subtract(
          noDesignCostExTaxAmt,
          this.getCategoryMoneyWithoutTax(design2)
        );
      }
      console.log("design2", design2);
      console.log("noDesignCostAmt", noDesignCostAmt);
      console.log("noDesignCostExTaxAmt", noDesignCostExTaxAmt);

      // 不含设计费的 总金额减总成本
      const amt_no_design = accurate.subtract(noDesignAmt, noDesignCostAmt);
      // 不含设计费的 扣税总金额减扣税总成本
      const exTaxAmt_no_design = accurate.subtract(
        noDesignExTaxAmt,
        noDesignCostExTaxAmt
      );

      return [
        {
          name: "项目毛利润（含设计费）",
          unit: "元",
          amt,
          exTaxAmt,
        },
        {
          name: "项目毛利润（不含设计费）",
          unit: "元",
          amt: amt_no_design,
          exTaxAmt: exTaxAmt_no_design,
        },
        {
          name: "项目毛利率(%)（含设计费）",
          unit: "%",
          amt: accurate.multiply(accurate.divide(amt / amtAll.amt), 100),
          exTaxAmt: accurate.multiply(
            accurate.divide(exTaxAmt / amtAll.exTaxAmt),
            100
          ),
        },
        {
          name: "项目毛利率(%)（不含设计费）",
          unit: "%",
          amt: accurate.multiply(
            accurate.divide(amt_no_design / noDesignAmt),
            100
          ),
          exTaxAmt: accurate.multiply(
            accurate.divide(exTaxAmt_no_design / noDesignExTaxAmt),
            100
          ),
        },
      ];
    },
  },

  mounted() {
    fetchDetail({
      id: this.$route.query.id,
    }).then((res) => {
      const detail = res ?? {};
      this.selectedContract = {
        code: detail.contractCode,
        name: detail.contractName,
      };
      this.totalAmt = detail.totalAmt;

      if (Array.isArray(res.amtList)) {
        this.amtList = res.amtList;
      }
      if (Array.isArray(res.expList)) {
        this.expList = res.expList.map((item) => {
          return {
            ...item,
            sort: String(item.sort),
          };
        });
      }

      if (Array.isArray(res.estCostList)) {
        this.estCostList = res.estCostList.map((item) => {
          return {
            ...item,
            date: moment(item.date, "YYYY-MM"),
            fileList:
              typeof item.attachments === "string"
                ? item.attachments.split(",")
                : [],
          };
        });
      }
    });
  },

  methods: {
    onSelectContract(contract) {
      console.log("contract", contract);
      this.selectedContract = contract;
    },

    onDeleteAmtList(index) {
      this.amtList.splice(index, 1);
    },

    addExp() {
      const arr = this.expList.filter((item) => item.sort.indexOf(".") === -1);

      if (arr.length > 0) {
        const last = arr[arr.length - 1];
        this.expList.push({
          name: "",
          sort: (parseInt(last.sort) + 1).toString(),
        });
      } else {
        this.expList.push({
          name: "",
          sort: "1",
        });
      }
    },

    onDeleteExpList(index) {
      if (index === this.expList.length - 1) {
        this.expList.splice(index, 1);
        return;
      }
      const current = this.expList[index];

      if (current.sort.indexOf(".") === -1) {
        for (let i = index + 1; i < this.expList.length; i++) {
          const item = this.expList[i];

          if (item.sort.indexOf(".") === -1) {
            item.sort = (parseInt(item.sort) - 1).toString();
          } else {
            const arr = item.sort.split(".");
            item.sort = parseInt(arr[0]) - 1 + "." + arr[1];
          }
        }
      } else {
        for (let i = index + 1; i < this.expList.length; i++) {
          const item = this.expList[i];
          if (parseFloat(item.sort) < parseInt(current.sort) + 1) {
            item.sort =
              parseInt(item.sort).toString() +
              "." +
              (parseInt(item.sort.split(".")[1]) - 1);
          }
        }
      }
      this.expList.splice(index, 1);
    },

    onDeleteEstCostList(index) {
      this.estCostList.splice(index, 1);
    },

    submit() {
      if (typeof this.totalAmt !== "number") {
        this.$message.error("请输入总承包总价");
        return;
      }

      if (!this.selectedContract.code) {
        this.$message.error("请选择合同");
        return;
      }

      if (this.amtList.length > 0) {
        let msg = "";
        for (let i = 0; i < this.amtList.length; i++) {
          const item = this.amtList[i];
          if (!item.name) {
            msg = "请输入请输入金额明细-金额名称";
            break;
          }
          if (typeof item.amt !== "number") {
            msg = "请输入金额明细-合同金额";
            break;
          }
          if (typeof item.taxRate !== "number") {
            msg = "请输入金额明细-税率";
            break;
          }
        }
        if (msg) {
          this.$message.error(msg);
          return;
        }
      }

      if (this.expList.length > 0) {
        let msg = "";
        for (let i = 0; i < this.expList.length; i++) {
          const item = this.expList[i];

          if (item.sort.indexOf(".") > 0) {
            if (!item.name) {
              msg = "请输入项目总支出-费用名称";
              break;
            }
            if (typeof item.amt !== "number") {
              msg = "请输入项目总支出-费用金额";
              break;
            }
            if (typeof item.taxRate !== "number") {
              msg = "请输入项目总支出-税率";
              break;
            }
            if (!item.supplyName) {
              msg = "请选择项目总支出-供应商名称";
              break;
            }
          }
        }
        if (msg) {
          this.$message.error(msg);
          return;
        }
      }

      if (this.estCostList.length > 0) {
        let msg = "";
        for (let i = 0; i < this.estCostList.length; i++) {
          const item = this.estCostList[i];
          if (!item.date) {
            msg = "请选择预计总成本表-月份";
            break;
          }
          if (item.fileList.length === 0) {
            msg = "请上传预计总成本表-附件";
            break;
          }
        }
        if (msg) {
          this.$message.error(msg);
          return;
        }
      }

      this.saveLoading = true;

      update({
        id: this.$route.query.id,
        contractCode: this.selectedContract.code,
        contractName: this.selectedContract.name,
        totalAmt: this.totalAmt,

        amtList: this.amtList.map((item) => {
          return {
            ...item,
            exTaxAmt: this.getMoney(item), // 不含税金额
          };
        }),

        expList: this.expList.map((item) => {
          let amt = item.amt;
          let exTaxAmt = 0;

          if (item.sort.indexOf(".") > 0) {
            exTaxAmt = this.getMoney(item);
          } else {
            amt = this.getCategoryMoney(item);
            exTaxAmt = this.getCategoryMoneyWithoutTax(item);
          }

          return {
            ...item,
            amt,
            exTaxAmt,
          };
        }),

        profitList: this.profitList,

        estCostList: this.estCostList.map((item) => {
          return {
            ...item,
            date: item.date.format("YYYY-MM"),
            attachments: item.fileList.join(","),
          };
        }),
      })
        .then(() => {
          this.$close(this.$route.path);
        })
        .finally(() => {
          this.saveLoading = false;
        });
    },

    getMoney(text) {
      if (typeof text.amt === "number" && typeof text.taxRate === "number") {
        return accurate.expr(`(100 - ${text.taxRate}) * ${text.amt} * 0.01`);
      } else {
        return 0;
      }
    },

    // 获取这个分类下所有的
    getCategoryMoney(text) {
      const arr = this.expList.filter(
        (item) => item.sort.indexOf(text.sort + ".") === 0
      );

      let amt = 0;
      arr.forEach((item) => {
        if (typeof item.amt === "number") {
          amt = accurate.add(amt, item.amt);
        }
      });

      return amt;
    },

    getCategoryMoneyWithoutTax(text) {
      const arr = this.expList.filter(
        (item) => item.sort.indexOf(text.sort + ".") === 0
      );

      let exTaxAmt = 0;

      arr.forEach((item) => {
        if (typeof item.amt === "number") {
          exTaxAmt = accurate.add(exTaxAmt, this.getMoney(item));
        }
      });

      return exTaxAmt;
    },

    saveDraft() {
      this.updateDraft({
        id: this.draftId ? this.draftId : undefined,
        title: this.draftTitle + " " + moment().format("YYYY-MM-DD"),
        type: this.draftType,
        content: JSON.stringify({
          selectedContract: this.selectedContract,
          totalAmt: this.totalAmt,
          amtList: this.amtList,
          expList: this.expList,
          estCostList: this.estCostList.map((item) => {
            return {
              ...item,
              date: item.date ? item.date.format("YYYY-MM") : undefined,
            };
          }),
        }),
      }).then(() => {
        this.draftVisible = false;
        this.draftTitle = "";
      });
    },

    onSelectDraft(draft) {
      if (draft.id && draft.content) {
        this.draftId = draft.id; // 暂存使用的草稿 id
        this.draftTitle =
          typeof draft.title === "string" ? draft.title.split(" ")[0] : "";

        const res = JSON.parse(draft.content);

        this.selectedContract = res.selectedContract;
        this.totalAmt = res.totalAmt;
        this.amtList = res.amtList;

        try {
          const fathers = res.expList
            .filter((item) => item.sort.indexOf(".") === -1)
            .map((item, index) => {
              return {
                oldSort: item.sort,
                newSort: (index + 1).toString(),
              };
            });

          if (res.expList[0] && String(res.expList[0].sort) === "0") {
            this.expList = res.expList.map((item) => {
              let sort = "";

              if (item.sort.indexOf(".") === -1) {
                const father = fathers.find(
                  (father) => father.oldSort === item.sort
                );
                sort = father.newSort;
              } else {
                const arr = item.sort.split(".");
                const father = fathers.find(
                  (father) => father.oldSort === arr[0]
                );
                sort = father.newSort + "." + arr[1];
              }

              return {
                ...item,
                sort,
              };
            });
          } else {
            this.expList = res.expList;
          }
        } catch (error) {
          alert(error);
        }

        this.estCostList = res.estCostList.map((item) => {
          return {
            ...item,
            date: item.date ? moment(item.date, "YYYY-MM") : undefined,
          };
        });
      }
    },
  },
};
</script>
  
  
  
<style lang="less" scoped>
.content {
  padding: 0 80px;

  .title {
    margin-bottom: 12px;
    margin-top: 12px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .value {
      font-weight: bold;
      color: #1890ff;
      font-size: 16px;
    }
  }
}

.footer {
  padding: 80px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>